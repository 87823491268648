html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

* {
  margin: 0;
  font-family: "Manrope", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
}

body {
  background-color: #fff;
}

.colors {
  color: #0d1321;
  color: #1d2d44;
  color: #3e5c76;
  color: #748cab;
  color: #f0ebd8;
}

h1 {
  color: #0d1321;
}

p {
}
